@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

html, body {
    overflow-x:hidden 
  } 

ul {
    margin-bottom: 0;
    padding-left: 0;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
}


.header_logo svg {
    display: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: white;
    cursor: pointer;
}

/* responsive */


@media screen and (min-width: 769px) and (max-width: 1600px) {
    .home_section .home_section_inner:nth-child(2)  {
        height: auto;
    }

    .home_top_child {
        height: 50%;
    }

    .home_top_child iframe {
        height: 100%;
    }

    .home_bottom_child {
        height: 50%;
    }

    .home_bottom_child img {
        height: 100%;
    }
}


@media screen and (max-width: 1200px) {
    .hamMenu-open {
        display: block !important;
    }

    header, .footer_fixed_position {
        position: static !important;
    }

    .header {
        flex-direction: column;
    }

    .header_navigation {
        margin-left: 0;
        display: none;
    }

    .top_navigation_section {
        padding: 0px 20px;
    }

    .header_navigation > ul > li {
        padding: 12px;
        border-bottom: 1px solid #999999;
        width: 100%;
        background-color: #e6e6e6;
        font-size: 14px;
        font-weight: 600;
    }

    .about_inner {
        flex-direction: column;
    }

    .header_navigation > ul {
        border-top: 2px solid #999999;
    }

    .header_logo {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
    }

    .header_logo svg {
        display: block;
        position: relative;
        left: 30%;
        font-size: 30px;
        color: #862633;
        flex: 1;
    }

    .header_navigation > ul {
        flex-direction: column;
        padding-left: 0;
        align-items: center;
    }

    .carousel-control-prev, .carousel-control-next {
        opacity: 0;
    }

    .motorcycle_category_inner {
        flex-basis: 32%;
        
    }

    .blog_item {
        flex-basis: 100%;
        padding: 15px;
        margin-bottom: 0;
    }

    .home_blog_container .blog_item:last-child {
        margin-bottom: 50px;
    }

    .home_blog_container {
        gap: 0;
    }


    .home_parallax {
        overflow: hidden;
        -webkit-background-size: cover;
    }

    .parallax_inner {
        text-align: center;
        padding: 0 20px;
    }

    .parallax_inner h2 {
        font-size: 20px;
    }

    .parallax_inner a {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .home_section {
        flex-direction: column;
        margin-top: 0;
    }
    .home_section .home_section_inner:first-child {
        width: 100%;
        padding-right: 0;
    }

    .home_section .home_section_inner:nth-child(2) {
        width: 100%;
        padding: 8px;
    }

    .blog_item {
        flex-basis: 40%;
    }

    header, .footer_fixed_position {
        position: static !important;
    }

}


@media screen and (max-width: 480px) {

    .hamMenu-open {
        display: block !important;
    }

    header, .footer_fixed_position {
        position: static !important;
    }

    .header {
        flex-direction: column;
    }

    .header_navigation {
        margin-left: 0;
        display: none;
    }

    .top_navigation_section {
        padding: 0px 20px;
    }

    .header_navigation > ul > li {
        padding: 12px;
        border-bottom: 1px solid #999999;
        width: 100%;
        background-color: #e6e6e6;
        font-size: 14px;
        font-weight: 600;
    }

    .about_inner {
        flex-direction: column;
    }

    .header_navigation > ul {
        border-top: 2px solid #999999;
    }

    .header_logo {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
    }

    .header_logo svg {
        display: block;
        position: relative;
        left: 18%;
        font-size: 30px;
        color: #862633;
        flex: 1;
    }

    .header_navigation > ul {
        flex-direction: column;
        padding-left: 0;
        align-items: center;
    }

    .home_section {
        margin-top: 0;
        flex-direction: column;
    }

    .home_section .home_section_inner:first-child {
        width: 100%;
        padding-right: 0;
    }

    .home_section .home_section_inner:nth-child(2) {
        width: 100%;
        padding: 8px;
    }

    .carousel-control-prev, .carousel-control-next {
        opacity: 0;
    }

    .motorcycle_category_inner {
        flex-basis: 50%;
        
    }

    .blog_item {
        flex-basis: 100%;
        padding: 15px;
        margin-bottom: 0;
    }

    .home_blog_container .blog_item:last-child {
        margin-bottom: 50px;
    }

    .home_blog_container {
        gap: 0;
    }


    .home_parallax {
        overflow: hidden;
        -webkit-background-size: cover;
    }

    .parallax_inner {
        text-align: center;
        padding: 0 20px;
    }

    .parallax_inner h2 {
        font-size: 20px;
    }

    .parallax_inner a {
        font-size: 14px;
    }
}

@media screen and (max-width: 375px) {
    .header_logo svg {
        display: block;
        position: relative;
        left: 10%;
        font-size: 30px;
        color: #862633;
        flex: 1;
    }
}