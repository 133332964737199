.history_sub_top {
    max-width: 1200px;
    margin: auto;
    text-align: center;
    margin-top: 40px;
}

.historysub_topimg {
    width: 100%;
}

.history_sub_top h1 {
    color: #862633;
    font-weight: 500;
    font-size: 26px;
}

.era_layout_container {
    display: flex;
    margin: auto;
    max-width: 1200px;
    align-items: center;
    margin-top: 40px;
}

.era_layout_reverse {
    flex-direction: row-reverse;
}

.era_layout_inner {
    flex: 1;
}

.era_layout_inner img {
    width: 100%;
}

.era_layout_text {
    padding: 0 40px;
    box-sizing: border-box;
}

.era_layout_text h1 {
    font-size: 22px;
    font-weight: 600;
}

.people_container {
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
}

.history_box_layout {
    display: flex;
    margin: 40px 20px;
    gap: 20px;
}

.history_box_inner {
    flex: 1;
}

.history_box_inner h3 {
    margin-top: 20px;
    font-size: 16px;
}

.history_box_inner img {
    width: 100%;
}

.history_box_inner p:nth-child(3) {
    font-size: 16px;
    font-weight: 600;
}

.history_box_inner p:nth-child(4) {
    font-size: 14px;
}


/* responsive */

@media screen and (max-width: 768px) {
    .historysub_topimg {
        margin-top: 0;
        width: 100%;
    }

    .history_sub_top {
        padding: 0 20px;
    }

    .era_layout_container {
        flex-direction: column;
    }

    .people_container {
        padding: 0 40px;
        text-align: left;
    }

    .history_box_layout {
        flex-direction: column;
    }
}