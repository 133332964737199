.top_navigation_section {
    background-color: #252525;
    height: 40px;
    width: 100%;
}

.top_navigation_section > div {
    max-width: 1200px;
    margin: auto;
    text-align: right;
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
}

.top_navigation_section svg {
    color: white;
}