.ridecommand_background {
    width: 100%;
}

.ridecommand_hero {
    background-color: #e6e6ea;
}

.ridecommand_hero_container {
    display: flex;
    margin: auto;
    max-width: 1200px;
    align-items: center;
    color: #882635;
    padding: 40px 0;
    gap: 20px;
}

.ridecommand_hero_wrapper img {
    width: 100%;
}

.ride_command_hero_inner h2 {
    font-weight: 500;
    font-size: 24px;
}

.ride_command_hero_inner p {
    font-family: 'Arial';
    color: #000;
}

.ridecommand_hero_container .ridecommand_hero_wrapper:first-child {
    width: 40%;
}

.ridecommand_hero_container .ridecommand_hero_wrapper:nth-child(2) {
    width: 60%;
}

.ridecommand_mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px 0;
    text-align: center;
}

.ridecommand_mobile h1 {
    color: #882635;
    font-weight: 500;
    font-size: 24px;
}

.ridecommand_mobile p {
    font-family: 'Arial';
}

.mobile_images {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.ridecommand_instructions {
    background: #e6e6ea;
    display: flex;
    justify-content: space-around;
    padding: 80px;
    text-align: center;
}

.instructions_wrapper {
    width: 20%;
}

.instructions_wrapper h3 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

.instructions_wrapper p {
    font-family: 'Arial';
}

.touring_feature img {
    width: 100%;
}


/* responsive */

@media screen and (max-width: 768px) {
    .ridecommand_hero_container {
        flex-direction: column;
    }

    .ride_command_hero_inner h2 {
        font-weight: 500;
        font-size: 19px;
    }

    .ridecommand_hero_wrapper {
        width: 100% !important;
        padding: 0 40px;
    }

    .ridecommand_mobile {
        flex-direction: column;
        padding: 40px;
    }

    .ridecommand_instructions {
        flex-direction: column;
        margin-top: 20px;
    }

    .instructions_wrapper, .mobile_images img, .ridecommand_mobile_inner img {
        width: 100%;
    }

    .mobile_images {
        justify-content: center;
    }

}