.history_background {
    width: 100%;
}

.history_top {
    background-color: #862633;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: white;
    padding: 50px;
}

.history_top h1 {
    font-size: 26px;
}

.historical_eras {
    text-align: center;
    padding: 40px 0;
}

.historical_title {
    color: #862633;
    font-size: 26px;
    font-weight: 500;
    padding-top: 14px;
}

.historycal_eras_wrapper {
    justify-content: space-around;
    flex-wrap: wrap;
    display: flex;
}

.historycal_eras_inner h2 {
    font-size: 18px;
    font-weight: normal;
}

.historycal_eras_inner p {
    font-size: 16px;
    font-family: 'Teko';
    margin-bottom: 0;
}

.eras_hero {
    border: 1px solid #862633;
    background-color: #862633;
    color:white;
    padding: 12px;
}

.history_experience {
    text-align: center;
}

.history_experience_container {
    display: flex;
    align-items: center;
    margin: 0 20px;
    background-color: #e5e5e5;
}

.history_experience_container .history_experience_inner:first-child {
    text-align: left;
    padding: 50px;
}

.history_experience_container .history_experience_inner:first-child h1 {
    font-weight: 500;
    font-size: 24px;
}


@media screen and (max-width: 768px) {
    .history_background {
        margin-top: 0;
    }

    .history_top  {
        padding: 20px;
    }
    .historycal_eras_inner img {
        width: 100%;
    }

    .historycal_eras_wrapper {
        padding: 0 20px;
        gap: 50px;
    }

    .historical_title {
        font-size: 32px;
        padding: 20px;
    }

    .history_experience_container {
        flex-direction: column;
    }

    .history_experience_inner img {
        width: 100%;
    }
}