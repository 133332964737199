.racing_hero {
    display: flex;
    text-align: center;
    align-items: center;
}

.racing_hero .racing_hero_inner:first-child {
    padding: 0 20px;
}

.racing_hero_inner {
    flex: 1;
}


.indianracing_top video {
    width: 100%;
}



.racing_hero_title {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    color: #862633;
}

.racing_hero_description {
    font-family: 'Arial';
    font-size: 15px;
}

.racing_hero_inner div iframe {
    width: 100%;
    height: 535px;
}

.racing_box {
    background: #faf5ec;
    text-align: center;
    padding: 10px 30px;
}

.bike_photo {
    display: flex;
    justify-content: center;
    margin: auto;
}

.hero_reverse {
    flex-direction: row-reverse;
}



/* responsive */

@media screen and (max-width: 1200px) {
    .racing_hero {
        flex-direction: column !important;
    }

    .racing_hero_title {
        font-size: 30px;
    }
}

@media screen and (max-width: 480px) {
    .racing_hero {
        flex-direction: column !important;
    }

    .racing_hero_title {
        font-size: 24px;
        padding: 10px 0;
    }

    .racing_hero_inner div iframe  {
        height: auto;
        padding-bottom: 20px;
    }

    .bike_photo {
        width: 100%;
    }

    .racing_hero img {
        width: 100%;
    }
}