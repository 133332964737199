
.dealers_list {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.dealers_list ul {
    display: flex;
}

.dealers_list ul li {
    margin: 0 0.5em;
    line-height: 1.4em;
    padding: 0 1.5em;
    font-weight: 700;
    font-size: 0.95em;
    letter-spacing: 1px;
    line-height: 2.5;
    color: #74777b;
}

.currentTab {
    background-color: #9c122a;
    color: #fff !important;
    cursor: pointer;
}

.normalTab {
    border-bottom: 3px solid #d2d8d6;
}

.normalTab:hover {
    border-bottom: 3px solid #9c122a;
    color: #9c122a;
    cursor: pointer;
    transition: 0.3s;
}



/* map view style css */

.mapview_container {
    display: flex;
    width: 100%;
    height: 60vh;
    align-items: center;
    justify-content: center;
}

.mapview_wrapper {
    min-width: 600px;
    margin: auto;
    text-align: center;
}

.mapview_wrapper h1 {
    font-size: 64px;
    margin-bottom: 1em;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
}

.mapview_input_wrapper {
    border: 1px solid #ccc;
    display: flex;
    width: 75%;
    margin: 1em auto;
    padding: 1em;
    font-size: 18px;
    align-items: center;
}

.mapview_input_wrapper input {
    width: 100%;
    border: 0;
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    text-align: left;
    line-height: inherit;
    color: #ccc;
}

.mapview_input_wrapper input:focus {
    border: 0;
    outline: 0;
}

.mapview_input_wrapper img {
    display: block;
    width: 21px;
    height: auto;
}

.mapview_button_wrapper {
    background: #862633;
    display: flex;
    width: 75%;
    margin: 1em auto;
    padding: 1em;
    font-size: 18px;
    align-items: center;
}

.mapview_button_wrapper button {
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    text-align: left;
    width: 100%;
    background: none;
    color: #fff;
    border: 0;
    line-height: 1;
}

.mapview_button_wrapper img {
    display: block;
    width: 21px;
    height: auto;
}

.country_dealer_container {
    padding: 0 40px;
}



/* Find A Dealer Responsive */

@media screen and (max-width: 425px) {
    .dealers_list {
        margin-top: 0;
    }

    .dealers_list ul {
        flex-direction: column;
        width: 100%;
    }

    .country_dealer_container {
        padding: 0 20px;
    }

    .country_dealer_full_list {
        flex-direction: column;
    }

    .dealer_single_information {
        width: 100% !important;
    }
}