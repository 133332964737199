.country_dealer_wrapper {
    max-width: 1200px;
    margin: auto;
}

.dealer_title {
    padding: 1em 0;
    border-bottom: 1px solid #ccc;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
}

.country_dealer_full_list{
    display: flex;
    flex-wrap: wrap;
}


.dealer_single_information {
    width: 50%;
    padding: 1em 0;
    border-bottom: 1px solid #ccc;
}

.dealer_single_header h3 {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.dealer_single_header h3 span {
    margin-right: 0.2em;
    color: #9c122a;
}


.dealer_single_content {
    margin-top: 1em;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    line-height: 1.5;
}

.dealer_single_content > div {
    display: flex;
    justify-content: start;
    margin-top: 1em;
    color: #000;
}

.dealer_single_content > div svg {
    font-size: 18px;
}


.dealer_single_content span{
    float: left;
}

.dealer_single_content p, .dealer_single_content a {
    display: block;
    margin-left: 1em;
    color: #000;
    text-decoration: underline;
    font-family: 'Arial';
}