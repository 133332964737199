.top_content {
    max-width: 1200px;
    margin: auto;
    padding: 150px 0;
    box-sizing: border-box;
    color: white;
    position: relative;
}

.top_content_text {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.top_content_text h1 {
    font-weight: 600;
    font-size: 30px;
}


.top_content_text p {
    font-size: 18px;
}

.motorcycle_price {
    font-size: 26px;
    font-weight: 600;
}

.motorcycle_hero_info {
    font-size: 14px;
    font-weight: normal;
}

.top_content_hero {
    position: absolute;
    bottom: 0;
    display: inline;
    right: 0;
    
}

.top_content_hero p {
    font-size: 14px;
    color: #fff;
    text-shadow: 2px 2px #000;
    opacity: 1;
    bottom: 0px
}


.single_motorcycle_button {
    margin-top: 10px;
}

.single_motorcycle_button a{
    background-color: #862633;
    padding: 7px 18px;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    min-width: 125px;
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    letter-spacing: 1px;
    font-weight: 600;
}

.motorcycle_radio_types {
    width: 24px;
    height: 24px;
    accent-color: #862633;
}


/*  widget css style */

.widget_container {
    background-color: #862633;
    padding: 12px 30px;
    color: white;
}

.widget_wrapper {
    display: flex;
}

.widget_inner {
    flex: 1;
}

.widget_heading {
    font-weight: 600;
    font-size: 20px;
}

.widget_hero {
    font-weight: normal;
    font-size: 12px;
}

.widget_list {
    display: flex;
}

.widget_list li a {
    padding: 7px 13px;
    color: white;
    text-transform: capitalize;
    font-size: 22px;
}


/* motorcycle detail css section */


.motorcycle_detail_container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 150px;
    align-items: center;
}

.motorcycle_detail_img {
    width: 60%;
}

.motorcycle_detail_wrapper {
    width: 40%;
}

.motorcycle_detail_img img {
    width: 100%;
}

.width-400 {
    width: 400px !important;
}

.motorcycle_detail_wrapper h1 {
    font-size: 30px;
    font-weight: 600;
    color: #862633;
}

.motorcycle_detail_colors p {
    font-weight: 600;
}

.bolded_price {
    font-weight: 700;
}

.color_swap_img {
    width: 35px;
    border: 1px solid #a8a9ad;;
    margin: 1em 0.5em;
    cursor: pointer;
    border-radius: 20px;
}


.motorcycle_detail_colors {
    margin-top: 1em;
}

.current_color{
    color: #000;
    font-weight: normal;
}


/* sound motorcycle css style */


.sound_motorcycle {
    text-align: center;
    display: flex;
    margin: 80px 0;
    padding: 0 30px;
    align-items: center;
}

.sound_text, .sound_diagram {
    flex: 1;
}

.sound_text h3 {
    font-weight: normal;
    font-size: 26px;
}

.sound_text p {
    font-family: 'Arial', 'Teko';
    font-size: 15px;
}

.sound_diagram video {
    display: block;
    width: auto;
    height: 550px;
    margin: auto;
}

.sound_diagram {
    position: relative;
}


.audio_controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.toggle_switch_engine {
    position: relative;
    width: 80px;
    height: 80px;
    background: #862633;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.toggle_switch_engine > span {
    position: absolute;
    top: 50%;
    left: 50%;
    padding-top: 4px;
    font-size: 18px;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    transition: opacity .2s ease;
}


/* featured style css section */ 


.featured {
    text-align: center;
    padding-bottom: 80px;
}

.featured_container h1 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
}

.featured_container p {
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: center;
}


.featured_items {
    display: flex;
    padding: 0 75px;
}


.featureditem {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 40px;
}

.featureditem h3, .featureditem p {
    padding: 0 25px;
    box-sizing: border-box;
    text-align: left;
}

.featureditem h3 {
    margin-top: 30px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
}

.featureditem p {
    margin-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.6em;
    font-weight: normal;
}

.featureditem img {
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
}


/* specifications style css section */

.specs_heading {
    color: #862633;
    text-transform: uppercase;
    text-align: center;
    font-size: 36px;
    font-weight: normal;
}

.specs_list {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
}

.specs_list ul {
    display: flex;
}


.specs_list ul li {
    margin: 0 0.5em;
    line-height: 1.4em;
    padding: 0 1.5em;
    font-weight: 700;
    font-size: 0.85em;
    letter-spacing: 1px;
    line-height: 2.5;
    color: #74777b;
}


.specs_table {
    width: 100%;
}

.table_title {
    padding: 8px;
    font-size: 22px;
    margin-top: 20px;
}

.specs_table_inner {
    display: flex;
    padding: 8px;
}

.specs_table_inner > h2 {
    flex: 1;
    font-size: 17px;
    margin-bottom: 0;
    font-weight: 500;
}

.table_inner_title {
    color: #74777b;
    font-weight: 400;
}

.specs_table_container .specs_table_inner:nth-of-type(odd) {
    background-color: #bbbbbb;
}


.scout_top_image {
    display: flex;
    margin: auto;
    padding-top: 20px;
}

.scout_detail_wrapper {
    padding: 0;
}

/*                   backgrounds                         */


.ftrrcarbon_background {
    background: url('./FTR\ Motorcycles/FTR Motorcycles Images/ftrrcarbon-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.ftrrally_background {
    background: url('./FTR\ Motorcycles/FTR Motorcycles Images/ftrrally-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.ftrsport_background {
    background: url('./FTR\ Motorcycles/FTR Motorcycles Images/ftrsport-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.ftr_background {
    background: url('./FTR\ Motorcycles/FTR Motorcycles Images/ftr-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.ftr_x_rsd_super_hooligan_background {
    background: url('./FTR\ Motorcycles/FTR Motorcycles Images/ftr-x-rsd-super-hooligan-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.ftr_x_r_carbon_background {
    background: url('./FTR\ Motorcycles/FTR Motorcycles Images/ftr-x-r-carbon-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

/* scout family backgrounds */

.scout_background {
    background: url('./Scout\ Motorcycles/Scout Motorcycles Images/scout-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}


.scoutbobber_background {
    background: url('./Scout\ Motorcycles/Scout Motorcycles Images/scoutbobber-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.scoutrogue_background {
    background: url('./Scout\ Motorcycles/Scout Motorcycles Images/scoutrogue_background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.scoutbobber20_background {
    background: url('./Scout\ Motorcycles/Scout Motorcycles Images/scoutbobber20-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

/* cruiser family backgrounds */

.chiefdarkhorse_background {
    background: url('./Cruiser\ Motorcycles/Cruiser Motorcycles Images/chiefdarkhorse-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.chiefbobberdarkhorse_background {
    background: url('./Cruiser\ Motorcycles/Cruiser Motorcycles Images/chiefbobberdarkhorsebackground.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.sportchief_background {
    background: url('./Cruiser\ Motorcycles/Cruiser Motorcycles Images/sportchief-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.superchieflimited_background {
    background: url('./Cruiser\ Motorcycles/Cruiser Motorcycles Images/superchieflimited-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

/* bagger family backgrounds */

.indianspringfield_background {
    background: url('./Bagger\ Motorcycles/Bagger Motorcycles Images/indianspringfield-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.isdh_background {
    background: url('./Bagger\ Motorcycles/Bagger Motorcycles Images/isdh-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.chieftainlimited_background {
    background: url('./Bagger\ Motorcycles/Bagger Motorcycles Images/chieftainlimited-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.indianchallengerlimited_background {
    background: url('./Bagger\ Motorcycles/Bagger Motorcycles Images/icl-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.chieftaindarkhorse_background {
    background: url('./Bagger\ Motorcycles/Bagger Motorcycles Images/chieftaindarkhorse-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.challengerdarkhorse_background {
    background: url('./Bagger\ Motorcycles/Bagger Motorcycles Images/challengerdarkhorse-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}


/* toruing family backgrounds */

.roadmaster_background {
    background: url('./Touring\ Motorcycles/Touring Motorcycles Images/roadmaster-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.roadmasterlimited_background {
    background: url('./Touring\ Motorcycles/Touring Motorcycles Images/roadmasterlimited-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.roadmasterdarkhorse_background {
    background: url('./Touring\ Motorcycles/Touring Motorcycles Images/rdh-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.ipl_background {
    background: url('./Touring\ Motorcycles/Touring Motorcycles Images/ipl-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.ipdh_background {
    background: url('./Touring\ Motorcycles/Touring Motorcycles Images/ipdh-background.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.challengerelite_background {
    background: url('../Motorcycles\ Family/Single Motorcycle Family/TOURING Family/Touring Family Images/challengerelite_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.indianchallengerelite_background {
    background: url('../Motorcycles\ Family/Single Motorcycle Family/ELITE Family/Elite Family Images/elite-family-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.scout_classic_1250_background {
    background: url('./Scout\ 1250cc\ Motorcycles/Scout\ 1250cc\ Motorcycles\ Images/scout-classic-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.scout_bobber_1250_background {
    background: url('./Scout\ 1250cc\ Motorcycles/Scout\ 1250cc\ Motorcycles\ Images/scout-bobber-1250-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.sport_scout_1250_background {
    background: url('./Scout\ 1250cc\ Motorcycles/Scout\ 1250cc\ Motorcycles\ Images/sport-scout-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.super_scout_1250_background {
    background: url('./Scout\ 1250cc\ Motorcycles/Scout\ 1250cc\ Motorcycles\ Images/super-scout-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.scout_101_1250_background {
    background: url('./Scout\ 1250cc\ Motorcycles/Scout\ 1250cc\ Motorcycles\ Images/101-scout-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}




/* carousel images style */

.carousel_images {
    max-width: 1200px;
    margin: auto;
    text-align: center;
    margin-top: 40px;
}

.carousel_images h1 {
    font-size: 26px;
}


/* touring feature style */

.touring_feature {
    text-align: center;
}

.touring_feature_inner {
    padding: 0 100px;
}

.touring_feature h1 {
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    margin-top: 40px;

}

.touring_feature h3 {
    font-size: 16px;
    font-family: 'Arial';
    font-weight: bold;
}

.touring_feature p {
    font-family: 'Arial';
}

.video_modal {
    width: 100%;
}

.modal-dialog {
    max-width: 800px;
}

.modal-body {
    padding: 0;
}



/* responsive for single motorcycle page */

@media screen and (max-width: 1200px) {
    .top_content_container {
        background-size: contain;
    background-repeat: no-repeat;
    }

    .top_content {
        margin: 0;
        color: #000;
        padding: 375px 0 40px 0;
    }
    .top_content_text {
        padding: 0 20px;
    }

    .top_content_hero {
        display: none;
    }

    .sound_motorcycle {
        flex-direction: column;
    }

    .sound_diagram video {
        width: 100%;
        height: auto;
    }

    .featured_items {
        padding: 0;
    }

    .carousel-item img {
        width: 100%;
    }
    
}



@media screen and (max-width: 768px) {
    .top_content_container {
        background-size: contain;
    background-repeat: no-repeat;
    }

    .top_content {
        margin: 0;
        color: #000;
        padding: 250px 0 40px 0;
    }
    .top_content_text {
        padding: 0 20px;
    }

    .top_content_hero {
        display: none;
    }

    .sound_motorcycle {
        flex-direction: column;
    }

    .sound_diagram video {
        width: 100%;
        height: auto;
    }

    .featured_items {
        padding: 0;
    }

    .carousel-item img {
        width: 100%;
    }
    
}


@media screen and (max-width: 480px) {

    .top_content_container {
        background-size: contain;
    background-repeat: no-repeat;
    }

    .top_content {
        margin: 0;
        color: #000;
        padding: 150px 0 40px 0;
    }

    .top_content_text {
        padding: 0 20px;
    }

    .top_content_hero {
        display: none;
    }
    
    .motorcycle_detail_container {
        padding-top: 40px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .motorcycle_detail_img {
        width: 100%;
    }

    .motorcycle_detail_wrapper {
        text-align: left;
        width: 100%;
        padding: 0 40px;
    }

    .single_motorcycle_button a {
        width: 100%;
    }

    .sound_motorcycle {
        flex-direction: column;
    }

    .sound_diagram video {
        width: 100%;
        height: auto;
    }

    .featured_container {
        padding: 0 20px;
    }

    .featured_container p {
        font-size: 14px;
    }

    .featured_items {
        flex-direction: column;
        padding: 0;
    }

    .featured_container h1 {
        font-size: 22px;
    }

    .featureditem img, .featureditem h3, .featureditem p {
        padding: 0;
    }

    .specs_heading {
        font-size: 22px;
    }

    .specs_list ul {
        flex-direction: column;
        width: 100%;
        text-align: center;
    }

    .table_title {
        font-size: 16px;
    }

    .specs_table_inner > h2 {
        font-size: 15px;
    }

    .specs_table, .specs_list ul {
        padding: 0 20px;
    }


    .carousel-item img {
        width: 100%;
    }

    .scout_top_image {
        width: 100%;
        padding: 20px;
    }

    .touring_feature img {
        width: 100%;
    }

    .touring_feature_inner {
        padding: 0 20px;
    }
}