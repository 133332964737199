.footer {
    background-color: #862633;
    padding: 50px 0 0 0;
}

.footer_container {
    position: relative;
}

.footer_wrapper img:first-child {
    position: absolute;
    top: 0;
    left: 5%;
}

.footer_wrapper img:nth-child(2) {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
}

.footer_social {
    width: 90%;
    margin: 32px auto;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: center;
    padding: 32px 0;
}

.footer_social svg {
    margin: 0 24px;
    font-size: 52px;
    line-height: 1;
    color: #fff;
}

.footer_copyright {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    padding-bottom: 40px;
}

.footer_copyright p {
    color: #fff !important;
    font-size: 14px;
}

.footer_copyright p:nth-child(2){
    margin-bottom: 3em;
}

.footer_fixed_position {
    background-color: #862633;
    border-top: 1px solid #fff;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.footer_fixed_position p {
    display: inline-block;
    float: left;
}


.footer_fixed_container {
    display: flex;
    max-width: 1200px;
    margin: auto;
    flex: 1;
    align-items: center;
}

.footer_fixed_container .footer_normal_showed {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    flex: 1;
}

.footer_fixed_container > ul > ul > li:first-child {
    border-bottom: 1px solid white;
    padding: 0;
    font-weight: 700;
}

.footer_fixed_container > ul > ul > li:nth-child(2) {
    margin-top: 10px;
}

.footer_fixed_container ul li {
    color: #fff;
    padding: 8px 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.logo_fixed_bottom {
    -webkit-filter: grayscale(1) brightness(999%);
    height: 50%;
}

.footer_fixed_position > svg {
    color: white;
    font-size: 24px;
    margin-left: 10px;
}

.footer_fixed_container ul li a {
    color: white;
}



.dropdown-toggle-dropup[aria-expanded='true'] {
    color: white;
}

.dropdown_footer {
    border-radius: 0;
    text-align: center;
    color: #b3b3b3;
    padding: 20px 0;
    border-top: 2px solid #b3b3b3;
}

.dropdown_footer > li, .dropdown_footer li a {
    color: #b3b3b3 !important;
    font-weight: 500;
}

.dropdown_footer > li:hover > a, .dropdown_footer > li:hover{
    color: #000 !important;
    cursor: pointer;
    background-color: #b3b3b3;
}

.footer_fixed_container ul li:hover .dropdown_footer {
    position: absolute;
    inset: auto auto 0px 0px;
    margin: 0px;
    transform: translate(-30px, -43px);
    display: block;
} 

details > summary {
    list-style: none;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }

.mobile_footer_hidden {
    display: none;
}

@media screen and (max-width: 1200px) {

    .footer_wrapper img:nth-child(2) {
        display: none;
    }

    .footer_wrapper img:first-child {
        position: static;
        width: 40%;
    }

    .footer_wrapper {
        text-align: center;
    }

    .footer_social  {
        padding: 20px 0;
    }

    .footer_copyright {
        text-align: center;
        margin: 32px;
    }

    .footer_fixed_container ul {
        flex-direction: column;
    }

    .footer_fixed_container ul {
        gap: 0px;
    }

    .footer_fixed_position svg {
        position: absolute;
    }


    .mobile_footer_hidden {
        display: flex !important;
        width: 100%;
    }

    .footer_normal_showed {
        display: none !important;
    }

    .footer_fixed_container {
        flex-direction: column;
    }

    .mobile_footer_hidden ul {
        border-bottom: 1px solid white;
    }

    .mobile_footer_hidden ul details {
        padding: 8px 0px;
        text-align: center;
    }
    .footer_fixed_container {
        padding: 0;
    }

    .mobile_inner {
        background: #f5f5f5;
    }

    .mobile_inner a {
        color: #b5b5b5 !important;
    }

    .logo_fixed_bottom {
        width: 10%;
        margin: auto;
        padding: 10px 0;
    }
}