

.imrg_background img {
    width: 100%;
}

.imrg_hero {
    display: flex;
    max-width: 1200px;
    justify-content: center;
    margin: auto;
    text-align: center;
    flex-direction: column;
}

.imrg_hero_wrapper {
    padding-top: 60px;
}

.imrg_hero_title {
    font-size: 16px;
    font-weight: 600;
}

.imrg_hero_description {
    font-size: 16px;
    font-family: 'Arial';
}

.imrg_videos_wrapper {
    padding-top: 60px;
    display: flex;
}

.imrg_videos_inner {
    flex: 1;
}

.imrg_links {
    background-color: #fff;
    border: 2px solid #862633;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1em;
    padding: 0.75em 1em;
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
}

.imrg_links:hover {
    background-color: #862633;
    color: white;
}

.imrg_picture2 {
    margin-top: 40px;
    width: 100%;
}

.imrg_picture2 img {
    width: 100%;
}


/* responsive */

@media screen and (max-width: 768px) {

    .imrg_background {
        margin-top: 0;
    }

    .imrg_hero_container {
        padding: 0 20px;
    }

    .imrg_videos_wrapper {
        flex-direction: column;
        gap: 50px;
        padding: 0 20px;
        margin-top: 20px;
    }

    .imrg_videos_inner video, .imrg_videos_inner img {
        width: 100%;
    }

    .imrg_picture2 {
        width: 100%;
    }


}