.about_us_top {
    max-width: 1200px;
    margin: 140px auto;
}

.about_us_top h1 {
    font-size: 26px;
    font-weight: 500;
}

@media screen and (max-width: 1200px) {
    .about_us_top {
        margin-top: 40px;
        padding: 0 20px;
    }

    .about_us_top h1 {
        font-size: 20px;
    }
}