
/* header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
} */



/* overlay */

.overlay {
    display: block;
    opacity: 0.7;
    background-color: #000000;
    height: 100%;
    position: fixed;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 2;
    zoom: 1;    
}

.dropdown-toggle[aria-expanded="true"] .overlay {
    z-index: 999;
    display: block;
    opacity: 0.7;
}




/* header */ 

.header {
    display: flex;
    background-color: #f9f9f9;
    border-top: 2px solid #862633;
    border-bottom: 1px solid #ccc;
}

.header_logo, .header_navigation {
    padding: 12px 0 0 0;
}


.header_logo {
    position: relative;
    left: 15px;
}

.header_logo img {
    width: 150px;
}

.header_navigation {
    margin-left: 40px;
}

.header_navigation > ul {
    display: flex;
}

.header_navigation ul li {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1em;
    letter-spacing: 1px;
    padding: 24px 20px;
    font-weight: 600;
}

.header_navigation ul li a {
    color: #000;
}

.header_navigation ul li a:hover {
    color: #862633;
}


.dropdown-toggle::after  {
    display: none;
}

.dropdown-menu {
    padding: 0;
    width: 100%;
    border: 0;
}

.dropdown-toggle[aria-expanded='true'] {
    color: #862633;
}


/* motorcycles dropdown style */

.motorcycles_dropdown {
    max-height: calc(100vh - 64px - 83px - 61px);
    overflow-y: auto;
    background-color: #ffffff;
    border-top: 1px solid #ccc;
    padding: 40px 40px 40px 20px;
    width: 100%;
}

.motorcycles_dropdown_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.motorcycles_dropdown_list {
    width: 20%;
}

.motorcycles_dropdown_list ul li {
    border-bottom: 2px solid rgb(229, 229, 229);
    padding: 0.8em 0;
}

.motorcycles_dropdown_list ul li p {
    font-size: 15px;
    font-family: 'Arial';
    text-transform: capitalize;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 0;
}

.motorcycles_dropdown_list ul li p:hover {
    color: #862633;
    cursor: pointer;
    border-bottom: 1px solid #862633;
}

.motorcycles_dropdown_content {
    width: 75%;
}

.motorcycles_family {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
}

.motorcycles_models {
    display: flex;
    flex-wrap: wrap;
}

.family_single_member {
    width: 25%;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.family_single_member p {
    font-weight: 700;
    font-size: 15px;
    margin-top: 6px;
    font-family: 'Arial';
    display: inline-block;
}

.family_single_member a {
    color: #000;
}

.family_single_member a:hover > p {
    border-bottom: 2px solid #862633;
}

.family_single_member img {
    width: 100%;
    transition: transform 0.5s ease;
}

.family_single_member:hover img {
    transform: scale(1.1);
  }


.motorcycles_family_bottom {
    background-color: #eee;
    padding: 10px 20px;
}

.motorcycles_family_bottom p {
    margin-bottom: 0;
    padding: 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: 'Arial';
}

.motorcycles_family_bottom a {
    color: #000;
    font-weight: normal;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: 'Arial';
}

.motorcycles_family_bottom p:hover > a{
    color: #862633;
    cursor: pointer;
    transform: 0.3s;
}

.dropdown_hidden {
    display: none;
}

.motorcycles_dropdown_list > svg, .about > svg {
    display: none;
    font-size: 30px;
    margin-bottom: 10px;
    width: 100%;
    background-color: #862633;
    border-radius: 20px;
    color: white;
}


/* About style css */


.about_container {
    max-width: 1200px;
    margin: auto;
    padding: 40px;
}

.about_inner {
    display: flex;
    flex-wrap: wrap;
}


.first_about{
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;

}
.about_item {
    text-align: center;
    flex: 1;
}

.about_item h2 {
    font-size: 16px;
    font-weight: normal;
    margin-top: 12px;
    text-transform: uppercase;
}

.about_item a {
    color: #000;
}

.about_item h3 {
    font-size: 18px;
    font-family: 'Arial';
    font-weight: 700;
    text-align: left;
}

.second_about {
    border-right: 1px solid #ccc;
    padding: 0 24px;
    margin-top: 12px;
    text-align: left;
}


.second_about p {
    font-family: 'Arial';
    margin-bottom: 0;
    font-size: 15px;
}

.last_about {
    border: 0;
}



/* responsive */


@media screen and (max-width: 480px) {

    .motorcycles_dropdown_list > svg, .about > svg {
        display: block;
    }

    .about > svg {
        margin-top: 20px;
        margin: 20px auto;
        width: 90%;
    }

    .motorcycles_dropdown_wrapper, .motorcycles_dropdown_list {
        width: 100%;
    }

    .motorcycles_dropdown_list ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;
    }

    .motorcycles_dropdown_list ul li {
        width: 30%;
    }

    .motorcycles_dropdown_list ul li:last-child {
        display: none;
    }

    .motorcycles_dropdown_list ul li:nth-child(7) {
        width: 100%;
    }

    .motorcycles_models {
        flex-direction: column;
        align-items: center;
    }

    .family_single_member, .motorcycles_dropdown_content {
        width: 100%;
    }

    .motorcycles_dropdown {
        padding: 20px;
        max-height: 100%;
    }
    .dropdown_fixed {
        transform:unset !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        width: 100%;
        height: 100%;
        z-index: 9999;
        overflow-y: auto;
    }

    .about_container {
        padding: 30px 0 0 0;
    }

    .about_container .about_inner:nth-child(2) {
        flex-direction: column;
    }

}