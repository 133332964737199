.cruiser_history {
    margin-top: 50px;
    margin-bottom: 50px;
}

.cruiser_history_container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    justify-content: center;
    text-align: center;
    padding: 0 50px;
    box-sizing: border-box;
}

.cruiser_history_logo {
    padding-top: 50px;
}

.cruiser_history_text {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.cruiser_history_text h3 {
    width: 30%;
    margin-right: 30px;
    text-align: right;
    font-size: 22px;
    font-weight: normal;
}

.cruiser_history_text p {
    width: 70%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: left;
    line-height: 1.4em;
}


.cruiser_history_images{
    display: flex;
    margin-top: 50px;
}

.cruiser_history_images img {
    width: 100%;
}

.cruiser_history_bottom {
    margin-top: 50px;
    display: flex;
    align-items: center;
}

.cruiser_history_bottom > div, .cruiser_history_bottom img {
    width: 50%;
}

.cruiser_history_bottom_inner {
    padding: 0 150px;
}

.cruiser_history_bottom_inner > h3, .cruiser_history_bottom_inner > p {
    font-weight: normal;
    text-align: left;
}

.cruiser_history_bottom_inner > h3 {
    font-size: 22px;
}

.cruiser_history_bottom_inner > p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.4em;
    margin-top: 1em;
}