.test_ride_container {
    display: flex;
    max-width: 700px;
    margin: auto;
    flex-direction: column;
    align-items: center;
}

.test_ride_heading {
    margin: 2em 0;
}

.test_ride_heading h1 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
}

.test_ride_wrapper {
    border-bottom: 2px solid #ccc;
    padding-bottom: 20px;
    width: 100%;
}

.test_ride_wrapper h3 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
}


.test_ride_model {
    text-align: center;
}


.test_ride_model img {
    width: 70%;
    cursor: pointer;
}

.test_ride_model h3 {
    display: inline-block;
    font-family: 'Arial';
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    text-transform: uppercase;
}

.test_ride_model h3:hover {
    border-bottom: 2px solid #862633;
    cursor: pointer;
}


/* Test Ride Models component */ 

.test_ride_models {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9999;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.models_header {
    padding: 20px;
    max-width: 1200px;
    text-align: center;
}

.models_header h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    display: inline-block;
}
.models_header svg {
    position: absolute;
    top: 20px;
    right: 10%;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.testride_models_list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #862633;
    flex-wrap: wrap;
}

.testride_models_list li {
    padding: 1em 24px;
    color: white;
    text-transform: uppercase;
}

.testride_models_list li:hover {
    background-color: #ccc;
    color: #000;
    cursor: pointer;
}

.model_active {
    background-color: #ccc;
    color: #000 !important;
}



/* Test Ride Form */


.testride_form_container {
    max-width: 700px;
    margin: auto;
    margin-top: 20px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 20px;
}

.testride_form_container h3 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
}

.testride_form_container input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 0 0 24px;
    background: #ccc;
    border: none;
    border-bottom: 3px solid #862633;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: inherit;
    word-spacing: inherit;
    margin-bottom: 24px;
}

.testride_input::placeholder {
    font-weight: 700;
    color: #000;
}

.testride_input:focus {
    outline: 0;
}

.form_button {
    display: flex;
    margin: auto;
    background-color: #862633;
    padding: 12px 60px;
    color: #fff;
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: 600;
}

.form_note {
    font-size: 15px;
}



/* responsive test ride */

@media screen and (max-width: 480px) {
    .test_ride {
        margin-top: 0;
    }

    .test_ride_wrapper, .testride_form_container {
        padding: 0 20px 20px;
    }

    .test_ride_models {
        overflow: auto;
    }

    .test_ride_heading h1  {
        font-size: 22px;
    }

    .test_ride_wrapper h3, .testride_form_container h3 {
        font-size: 14px;
    }

    .test_ride_model img {
        width: 100%;
    }
}