.home_section {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.home_section .home_section_inner:first-child {
    padding-right: 10px;
    box-sizing: border-box;
    width: 70%;
}

.home_section .home_section_inner:nth-child(2) {
    width: 30%;
}

.home_top_child, .home_bottom_child {
    width: 100%;
}

.home_section_inner img {
    width: 100%;
    height: 100%;
}

.home_top_child iframe {
    width: 100%;
}

.home_section_inner {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


/* top background images */

.carousel, .carousel-inner, .carousel-item {
    height: 100%;
}

.carousel-control-next, .carousel-control-prev {
    width: 4%;
    opacity: 1;
}

.carousel-control-next svg, .carousel-control-prev svg{
   font-size: 30px;
   background-color: #fff;
   border-radius: 20px;
   color: olive;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: #bababa;
    border-radius: 20px;
    padding: 10px;
}



/* hero section css and button */

.home_buttoncta {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.home_buttoncta a {
    font-size: 16px;
    background-color: #862633;
    font-weight: normal;
    cursor: pointer;
    padding: 1em;
    color: #fff;
    line-height: 1em;
}

.home_buttoncta a:hover {
    background-color: #000;
}


.home_motorcycle_category {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.motorcycle_category_inner{
    flex-basis: 33%;
    text-align: center;
}

.motorcycle_category_inner img {
    width: 100%;
}

.motorcycle_category_inner h1 {
    font-size: 20px;
    font-weight: normal;
    line-height: 0.85em;
}


/* blog section style */


.home_blog_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    gap: 20px;
}

.blog_item {
    margin-top: 50px;
    margin-bottom: 50px;
    flex-basis: 49%;
    text-align: center;
}

.blog_item img {
    width: 100%;
}


.blog_item_title {
    background-color: #fff;
    border: 2px solid #862633;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    padding: 1em;
    cursor: pointer;
    display: inline-block;
    margin-top: 14px;
}

.blog_item_title:hover {
    background-color: #862633;
    color: white;
}


/* parallax style */

.home_parallax {
    background: url('../Home\ Page/Home  Page Images/rassemblement.webp') top center no-repeat fixed;;
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallax_inner {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex-direction: column;
}

.parallax_inner h2 {
    font-size: 26px;
    color: #fff;
    margin-bottom: 12px;
    font-weight: normal;
    text-transform: uppercase;
}

.parallax_inner a {
    border: 2px solid #862633;
    color: #000;
    background-color: #fff;
    font-size: 14px;
    line-height: 1em;
    padding: 0.8em 1.6em;
    text-transform: uppercase;
    font-weight: 600;
}

.parallax_inner a:hover {
    background-color: #862633;
    color: #fff;
}