.family_background {
    position: relative;
    overflow: hidden;
}

.family_background img {
    width: 100%;
}

.hero_heading {
    display: flex;
    margin-top: 30px;
    max-width: 1200px;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}

.hero_heading h1 {
    text-align: right;
    padding-right: 30px;
    color: #862633;
    font-size: 56px;
    letter-spacing: .1em;
    font-weight: normal;
}

.hero_heading p {
    width: 70%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.4em;
    margin-bottom: 0;
}


/* overview component css */

.family_overview {
    display: flex;
    margin: 30px 150px 0 150px;
    padding: 50px;
    background: #e5e5e5;
}

.family_overview_item {
    width: calc(100% / 3);
    text-align: center;
    margin-right: 30px;
}

.family_overview_item h1 {
    font-size: 20px;
    font-weight: 600;
}

.family_overview_item p {
    line-height: 1.4em;
    margin-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
}


/* videos section css */

.family_videos {
    max-width: 1200px;
    margin: auto;
    margin-top: 5rem;
}


/* meet the family component css */ 

.mtf_heading {
    margin-top: 50px;
    text-align: center;
}

.mtf_heading h2 {
    font-size: 40px;
    letter-spacing: .1em;
    color: #862633;
    font-weight: 500;
}

.mtf_bikes {
    padding: 50px;
}

.mtf_bikes_wrapper {
    display: flex;
    margin: 0 75px;
    flex-wrap: wrap;
    justify-content: center;
}


.single_mtf_motorcycle {
    width: calc(50% - 25px);
    text-align: center;
    padding-bottom: 40px;
    box-sizing: border-box;
}

.single_mtf_motorcycle > div img {
    width: 100%;
}

.single_mtf_motorcycle h3 {
    padding-bottom: 4px;
    font-size: 24px;
    color: #000;
    border-bottom: 2px solid transparent;
    font-weight: normal;
}

.price_wrapper span {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: center;
}

.price_wrapper span span {
    font-size: 20px;
    font-weight: 700;
}

.button_wrapper {
    margin-top: 40px;
    text-align: center;
}

.button_wrapper a {
    font-size: 16px;
    padding: 13.5px 0.75em;
    background-color: transparent;
    border: 2px solid #862633;;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
}

.button_wrapper a:hover {
    background-color: #862633;
    color: white;
}



/* families responsive */


@media screen and (max-width: 768px) {

    .family_background {
        margin-top: 0;
    }

    .hero_heading {
        justify-content: center;
        padding: 20px 0;
    }

    .family_overview {
        margin: 0;
    }

    .ftr_history_text, .scout_history_text, .cruiser_history_text, .bagger_history_text, .touring_history_text, .darkhorse_history_text {
        flex-direction: column;
    }

    .ftr_history_text h3, .scout_history_text h3, .cruiser_history_text h3, .bagger_history_text h3, .touring_history_text h3, .darkhorse_history_text h3 {
        text-align: left !important;
        width: 100% !important;
    }

    .ftr_left_image {
        display: none;
    }

    .ftr_right_image {
       width: 100% !important;
    }

    .ftr_history_bottom, .scout_history_bottom, .cruiser_history_bottom, .bagger_history_bottom {
        flex-direction: column;
    }

    .ftr_history_bottom_inner, .scout_history_bottom_inner, .cruiser_history_bottom_inner, .bagger_history_bottom_inner {
        padding: 0px !important;
    }

    .ftr_history_bottom > div, .scout_history_bottom > div, .cruiser_history_bottom > div, .bagger_history_bottom > div {
        width: 100% !important;
    }
    .ftr_history_bottom img,  .scout_history_bottom img, .cruiser_history_bottom img, .bagger_history_bottom img {
        width: 100% !important;
        padding-bottom: 20px;
    }

    .scout_history_container, .cruiser_history_container, .bagger_history_container, .touring_history_container, .darkhorse_history_container{
        padding: 0 20px !important;
    }

    .mtf_bikes_wrapper 
    {
        margin: 0;
    }
}

@media screen and (max-width: 480px) {
    .family_background {
        margin-top: 0px;
    }

    .hero_heading {
        flex-direction: column;
        text-align: center;

    }

    .hero_heading h1 {
       padding-right: 0;
       font-size: 24px;
       margin-top: 0.5rem;
    }

    .family_overview {
        margin: 0;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .family_overview_item {
        margin-right: 0;
        width: 100%;
    }

    .mtf_heading h2 {
        font-size: 24px;
    }

    .mtf_bikes_wrapper {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .button_wrapper a {
        font-size: 12px;
        padding: 13.5px 0.5em;
    }

    .mtf_bikes {
        padding: 20px;
    }

    .single_mtf_motorcycle {
        width: 100%;
    }

    .ftr_history_text, .scout_history_text, .cruiser_history_text, .bagger_history_text, .touring_history_text, .darkhorse_history_text {
        flex-direction: column;
    }

    .ftr_history_text h3, .scout_history_text h3, .cruiser_history_text h3, .bagger_history_text h3, .touring_history_text h3, .darkhorse_history_text h3 {
        text-align: left !important;
        width: 100% !important;
    }

    .ftr_left_image {
        display: none;
    }

    .ftr_right_image {
       width: 100% !important;
    }

    .ftr_history_bottom, .scout_history_bottom, .cruiser_history_bottom, .bagger_history_bottom {
        flex-direction: column;
    }

    .ftr_history_bottom_inner, .scout_history_bottom_inner, .cruiser_history_bottom_inner, .bagger_history_bottom_inner {
        padding: 0px !important;
    }

    .ftr_history_bottom > div, .scout_history_bottom > div, .cruiser_history_bottom > div, .bagger_history_bottom > div {
        width: 100% !important;
    }
    .ftr_history_bottom img,  .scout_history_bottom img, .cruiser_history_bottom img, .bagger_history_bottom img {
        width: 100% !important;
        padding-bottom: 20px;
    }

    .scout_history_container, .cruiser_history_container, .bagger_history_container, .touring_history_container, .darkhorse_history_container{
        padding: 0 20px !important;
    }
    .scout_history_logo img, .cruiser_history_logo img, .bagger_history_logo img, .touring_history_logo img, .darkhorse_history_logo img {
        width: 100%;
    }
}